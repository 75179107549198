import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const ClassTeaser = ( {title,img,excerpt,link} ) => (
   <Link to={link} className="d-block">
      <div className="c-class-teaser">
          <div className="c-class-teaser__visu">
              <GatsbyImage image={img.childImageSharp.gatsbyImageData} />
              <h2 className="u-mt:0 c-class-teaser__title d-flex align-items-center justify-content-center">{ title }</h2>
          </div>
          <div className="c-class-teaser__body u-pt:1">
              {excerpt}
          </div>
      </div>
  </Link>
)

export default ClassTeaser
