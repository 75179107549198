import React from "react"
import { graphql } from "gatsby";

// for html from frontmatter
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Quote from "../components/Quote.js"
import TeacherLink from "../components/TeacherLink.js"
import ClassTeaser from "../components/ClassTeaser"

const IndexPage = ( {data} ) => {

    let intro = data.home.frontmatter.intro
    intro = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(intro).toString();

    let quote = data.home.frontmatter.quote
    quote = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(quote).toString();

    let classes = data.classes.edges

    return <Layout>
      <Seo title="" />
          <section className="o-section ts:lead u-color-bg:white">
              <div className="container u-pb:3">
                  <div className="row">
                  <div className="col-md-10 col-xl-7 mx-auto u-ta:c ts:lead u-color:dark-theme" dangerouslySetInnerHTML={{ __html: intro }} />
                  </div>
              </div>
          </section>
          {/* <section className="c-msg">
            <div className="container">
              <div className="row">
                <div className="col-md-11 col-lg-8 mx-auto">
                  <div className="u-color-bg:theme u-px:3 u-py:2 u-ta:c u-color:white u-mt:-3" style={{fontWeight:'600'}} >
                    <span className="ts:lead">Saison 2021-2022</span><br/>
                    <span>_______________</span><br/>
                    <p>Reprise des cours à partir du 20 septembre (semaine 38), sauf empêchement si nouvelles mesures relevant du contexte sanitaire.</p>
                    </div>
                </div>
              </div>
            </div>
          </section> */}
          <section className="o-section">
              <div className="container">
                  <div className="row o-list justify-content-around">
                    {classes.map((item) => {
                        const itemData =  item.node.frontmatter;
                        return (
                            <div key={itemData.title} className="col-sm-6 col-md-5">
                                <ClassTeaser title={itemData.title} excerpt={itemData.excerpt} img={itemData.featuredImage} link={itemData.path}/>
                            </div>
                        );
                    })}
                  </div>
              </div>
          </section>

          {quote &&
          <Quote body={quote} />
          }

          <TeacherLink />




    </Layout>
}
export default IndexPage



export const pageQuery = graphql`
query IndexQuery {
  home: markdownRemark(frontmatter: {templateKey: {eq: "home"}}) {
    frontmatter {
      intro
      quote
    }
  },
  classes: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "ClassTemplate"}, path: {ne: "/seances-individuelles"}}}, sort: {fields: frontmatter___order, order: ASC}) {
    edges {
      node {
        frontmatter {
          title
          excerpt
          path
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 486
                quality: 90
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
}

`
